import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  
  g: Globals;
  state : any  = "hide";
  themeImage : any = "../../assets/images/SVG/dark_mode.svg";
  constructor(public globals: Globals){
      this.g = globals;
  }
  
  themeChange(){
    if(this.g.theme == "dark"){
        this.globals.theme = "light";
        this.themeImage = "../../assets/images/SVG/dark_mode.svg";
        document.body.setAttribute('class','');
    }else{
        this.globals.theme = "dark";
        this.themeImage = "../../assets/images/SVG/light mode.svg";
        document.body.setAttribute('class','dark'); 
       
          this.g.theme = "dark";
          this.themeImage = "../../assets/images/SVG/light mode.svg";
    }
  }

  langChange(){
    if(this.g.language == "Fr"){
      this.globals.language = "En";
    }else{
      this.globals.language = "Fr";
    }
  }

  toggle(){
    if(this.state == "hide"){
      this.state = "";
    }else{
      this.state = "hide";
    }
  }
  ngOnInit(): void {
   
  }

}
