import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  g : Globals ;

  waitlistEmail = new FormControl('');
  constructor(public globals: Globals){
    this.g = globals;
  }

  ngOnInit(): void {
  }


}
