<div class="team-container" *ngIf="g.language === 'En'">
  <div class="title">
    <h1>The Guild</h1>
  </div>
  <div class="title-sub">
    <h1>Passionate Team. Innovative Ideas.</h1>
  </div>
  <div class="image-cards">
    <div class="image-row">
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/ravi1.png" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Ravi</h1>
          </div>
          <div class="card-sub">
            <h1>CO Founder & CEO</h1>
          </div>

          <a href="https://www.linkedin.com/in/ravi-navalarch/" target="_blank">
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>

          <br />
          <div class="card-desc">
            <p>
              Naval Architect with experience in Ship building & vessel
              chartering , alongside expertise in imports - exports and
              blockchain services in supply chain.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/dheeraj.png" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Dheeraj Kumar</h1>
          </div>

          <div class="card-sub">
            <h1>CO Founder & COO</h1>
          </div>
          <a
            href="https://www.linkedin.com/in/dheerajkumar2801/"
            target="_blank"
          >
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              Software industry and International Business professional with
              previous experience in managing CITIs trading GUI and debit and
              Credit applications.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/Chakradhar.jpeg" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Chakradhar</h1>
          </div>

          <div class="card-sub">
            <h1>CO Founder & CPO</h1>
          </div>
          <a
            href="https://www.linkedin.com/in/chakradhar-dhulipalla/"
            target="_blank"
          >
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              A blockchain product manager with four years’ experience in crypto
              and the distinction of managing trade deals worth $10 million.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="image-row">
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/edward.png" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Edward Ryall</h1>
          </div>
          <div class="card-sub">
            <h1>CFO</h1>
          </div>
          <a href="https://www.linkedin.com/in/ryall/" target="_blank">
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              An experienced start-up mentor with the background of founding
              Ryall Energy and serving as the CEO of AVE.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/nigam.jpeg" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Nigam Saini</h1>
          </div>
          <div class="card-sub">
            <h1>Co-Founder & CTO</h1>
          </div>
          <a href="https://www.linkedin.com/in/nigamsaini/" target="_blank">
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              An expert programmer with the all-round experience of managing
              blockchain projects from ideation to architecture development,
              through coding.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/shivkaran.jpg" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Shiva Karan</h1>
          </div>
          <div class="card-sub">
            <h1>Co-Founder & Product Designer</h1>
          </div>

          <a href="https://www.linkedin.com/in/shiva-karan/" target="_blank">
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              He focuses on delivering innovative trade financing solutions for
              MSMEs through decentralized finance (DeFi) and tokenization. He is
              passionate about using technology to drive impactful
              change in global trade.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="image-row">
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/foued.jpeg" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Foued Touzi</h1>
          </div>
          <div class="card-sub">
            <h1>Smart Contract Developer</h1>
          </div>
          <a href="https://www.linkedin.com/in/foued-touzi/" target="_blank">
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              Former IT consultant, now blockchain and web3 expert with 4 years
              of experience, Deeply involved into software development, I aspire
              to turn innovant ideas into new business.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/riya-rana.png" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Riya Rana</h1>
          </div>
          <div class="card-sub">
            <h1>Full-Stack Developer</h1>
          </div>
          <a
            href="https://www.linkedin.com/in/riya-rana-261a3919b/"
            target="_blank"
          >
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              Passionate Full-Stack Developer with good knowledge of data
              Structure and Algorithms. Developing scalable web applications and
              working across the full stack.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="team-container" *ngIf="g.language === 'Fr'">
  <div class="title">
    <h1>La Guilde</h1>
  </div>
  <div class="title-sub">
    <h1>Une équipe passionnée. Des idées novatrices.</h1>
  </div>
  <div class="image-cards">
    <div class="image-row">
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/ravi1.png" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Ravi</h1>
          </div>
          <div class="card-sub">
            <h1>CO Fondateur et CEO</h1>
          </div>
          <a href="https://www.linkedin.com/in/ravi-navalarch/" target="_blank">
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>

          <br />
          <div class="card-desc">
            <p>
              Architecte naval ayant de l'expérience dans la construction navale
              et l'affrètement de navires. l'affrètement de navires, ainsi
              qu'une expertise en matière d'importations - exportations et de
              services de blockchain dans la chaîne d'approvisionnement.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/dheeraj.png" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Dheeraj Kumar</h1>
          </div>
          <div class="card-sub">
            <h1>CO Fondateur & COO</h1>
          </div>
          <a
            href="https://www.linkedin.com/in/dheerajkumar2801/"
            target="_blank"
          >
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              Professionnel de l'industrie du logiciel et du commerce
              international avec un précédent expérience dans la gestion de
              l'interface graphique de négociation des CITI et des applications
              de débit et de crédit.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/Chakradhar.jpeg" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Chakradhar</h1>
          </div>
          <div class="card-sub">
            <h1>CO Fondateur & CPO</h1>
          </div>
          <a
            href="https://www.linkedin.com/in/chakradhar-dhulipalla/"
            target="_blank"
          >
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              Un chef de produit blockchain avec quatre ans d'expérience en
              crypto et la distinction de gérer des transactions commerciales
              d'une valeur de 10 millions de dollars
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="image-row">
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/edward.png" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Edward Ryall</h1>
          </div>
          <div class="card-sub">
            <h1>CFO</h1>
          </div>
          <a href="https://www.linkedin.com/in/ryall/" target="_blank">
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              Un mentor expérimenté dans le domaine de la création
              d'entreprises, qui a fondé Ryall Energy et a été PDG d'AVE. Ryall
              Energy et en tant que PDG d'AVE.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/nigam.jpeg" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Nigam Saini</h1>
          </div>
          <div class="card-sub">
            <h1>Co-fondateur & CTO</h1>
          </div>
          <a href="https://www.linkedin.com/in/nigamsaini/" target="_blank">
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              Un programmeur expert avec une expérience complète de la gestion
              projets blockchain de l'idéation au développement de
              l'architecture, grâce au codage.
            </p>
          </div>
        </div>
      </div>

      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/shivkaran.jpg" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Shiva Karan</h1>
          </div>
          <div class="card-sub">
            <h1>Co-fondateur et concepteur de produits</h1>
          </div>

          <a href="https://www.linkedin.com/in/shiva-karan/" target="_blank">
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              Il se concentre sur la fourniture de solutions innovantes de
              financement du commerce pour les MPME grâce à la finance
              décentralisée (DeFi) et à la tokenisation. Il est passionné par
              l'utilisation de la technologie pour susciter des changements
              percutants dans le commerce mondial.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="image-row">
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/foued.jpeg" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Foued Touzi</h1>
          </div>
          <div class="card-sub">
            <h1>Développeur de Smart Contract</h1>
          </div>
          <a href="https://www.linkedin.com/in/foued-touzi/" target="_blank">
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              Ancien consultant informatique, maintenant expert blockchain et
              web3 avec 4 ans d'expérience, Profondément impliqué dans le
              développement logiciel, j'aspire à transformer des idées
              innovantes en nouvelles affaires.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/riya-rana.png" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Riya Rana</h1>
          </div>
          <div class="card-sub">
            <h1>Développeur Full-Stack</h1>
          </div>
          <a
            href="https://www.linkedin.com/in/riya-rana-261a3919b/"
            target="_blank"
          >
            <i class="fa fa-linkedin fa-1x" aria-hidden="true"></i
          ></a>
          <br />
          <div class="card-desc">
            <p>
              Développeur Full-Stack passionné avec une bonne connaissance de la
              structure des données et des algorithmes. Développer des
              applications web évolutives et travailler sur l'ensemble de la
              pile.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
