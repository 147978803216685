import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.css']
})
export class TokenComponent implements OnInit {

 
  g : Globals;
  constructor(public globals: Globals){
    this.g = globals;
  }
  
  ngOnInit(): void {
  }
}
