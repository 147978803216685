import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css'],
})
export class SolutionsComponent implements OnInit {
  g : Globals;
  theme : any;
  dark : 'dark' = "dark";
  light : 'light' = "light";
  constructor(public globals: Globals){
    this.g = globals;
  }
  
  ngOnInit(): void {
    document.addEventListener('click',()=>{
      this.theme = (this.globals.theme == 'light') ? this.light : this.dark;
    })
  }
  
}
