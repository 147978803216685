<div class="token-container" *ngIf="g.language === 'En'">
  <div class="title">
    <h1>The ANTLP Token</h1>
  </div>
  <div class="title-sub">
    <h1>Enabling Governance</h1>
  </div>
  <div class="desc">
    <p>
      ANTLP token for the Antelope ecosystem, facilitating distributed
      governance, liquidity mining, and reward sharing. ANTLP is an expression
      of community orientation and the team receives its allocation only when
      the protocol fulfils its promise.
    </p>
  </div>
  <div class="button">
    <button>Get ANTLP</button>
  </div>
</div>
<div class="token-container" *ngIf="this.g.language === 'Fr'">
  <div class="title">
    <h1>Le site ANTLP Token</h1>
  </div>
  <div class="title-sub">
    <h1>Gouvernance habilitante</h1>
  </div>
  <div class="desc">
    <p>
      ANTLP token pour l'écosystème Antelope, facilitant la gouvernance distribuée, 
      l'extraction de liquidités et le partage des récompenses. ANTLP est une 
      expression de l'orientation communautaire et l'équipe ne reçoit son allocation 
      que lorsque le protocole remplit sa promesse.
    </p>
  </div>
  <div class="button">
    <button>Obtenez ANTLP</button>
  </div>
</div>
