<div class="about-component" *ngIf="g.language === 'En'">
  <div class="solutions-section">
    <div class="heading mobile">
      <p class="title">A One Stop Solution</p>
      <p class="title-sub">Breaking Silos.Smartening Trade.</p>
  
    </div>
    <div class="content">
      <div class="text">
        <span class="title web"> A One Stop Solution </span>
        <span class="title-sub web"> Breaking Silos.Smartening Trade. </span>
         
        <p class="desc">
          <strong>AntelopeFi</strong> is a comprehensive, integrated, and
          cross-chain ecosystem designed to resolve the problems of
          fragmentation and usability. The platform connects stakeholders,
          lowers barriers, and enhances access by offering an end-to-end
          solution for the globally expanding decentralized trade and logistics
          sector.
        </p>
      </div>
      <carousel
        class="slider icon-cards web"
        [width]="830"
        [arrowsOutside]="true"
        [height]="230"
        [arrowsTheme]="this.theme"
      >
        <div class="carousel-cell">
          <div class="icon-card card-1">
            <img src="../../assets/images/SVG/solution-1.svg" alt="" />
            <p class="card-title">Access to Defi Liquidity Pools</p>
            <p class="card-text">
              For cross-chain and cost-optimized trade financing
            </p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="icon-card card-2">
            <img src="../../assets/images/SVG/solution-2.svg" alt="" />
            <p class="card-title">Tokenize Invoices into NFT’s</p>
            <p class="card-text">
              For frictionless verification, transfer, and storage
            </p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="icon-card card-3">
            <img src="../../assets/images/SVG/solution-3.svg" alt="" />
            <p class="card-title">Leverage Decentralized Oracles</p>
            <p class="card-text">
              For off-chain integration with real world processes
            </p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="icon-card card-4">
            <img src="../../assets/images/SVG/solution-4.svg" alt="" />
            <p class="card-title">Assess credit and Insurance Coverage</p>
            <p class="card-text">For effective risk-minimization</p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="icon-card card-5">
            <img src="../../assets/images/SVG/solution-5.svg" alt="" />
            <p class="card-title">Peer-to-Peer Channels</p>
            <p class="card-text">
              For Optimal <br />
              Security of Trade
            </p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="icon-card card-6">
            <img src="../../assets/images/SVG/solution-6.svg" alt="" />
            <p class="card-title">Crypto & Fiat Conversion</p>
            <p class="card-text">For trustless financial interactions</p>
          </div>
        </div>
      </carousel>
      <div class="image mobile">
        <img
          *ngIf="this.globals.theme == 'dark'"
          src="../../assets/images/Sailing Schedule Screen.svg"
          alt=""
        />
        <img
          *ngIf="this.globals.theme == 'light'"
          src="../../assets/images/Sailing Schedule Screen-Light.svg"
          alt=""
        />
      </div>
    </div>
    <div class="image web">
      <img
        *ngIf="this.globals.theme == 'dark'"
        src="../../assets/images/Sailing Schedule Screen.svg"
        alt=""
      />
      <img
        *ngIf="this.globals.theme == 'light'"
        src="../../assets/images/Sailing Schedule Screen-Light.svg"
        alt=""
      />
    </div>
  </div>
  <carousel
    class="slider icon-cards mobile"
    [height]="185"
    [arrows]="false"
    [cellsToShow]="2"
  >
    <div class="carousel-cell">
      <div class="icon-card card-1">
        <img src="../../assets/images/SVG/solution-1.svg" alt="" />
        <p class="card-title">Access to Defi Liquidity Pools</p>
        <p class="card-text">
          For cross-chain and cost-optimized trade financing
        </p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="icon-card card-2">
        <img src="../../assets/images/SVG/solution-2.svg" alt="" />
        <p class="card-title">Tokenize Invoices into NFT’s</p>
        <p class="card-text">
          For frictionless verification, transfer, and storage
        </p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="icon-card card-3">
        <img src="../../assets/images/SVG/solution-3.svg" alt="" />
        <p class="card-title">Leverage Decentralized Oracles</p>
        <p class="card-text">
          For off-chain integration with real world processes
        </p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="icon-card card-4">
        <img src="../../assets/images/SVG/solution-4.svg" alt="" />
        <p class="card-title">Assess credit and Insurance Coverage</p>
        <p class="card-text">For effective risk-minimization</p>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="icon-card card-5">
        <img src="../../assets/images/SVG/solution-5.svg" alt="" />
        <p class="card-title">
          Peer-to-Peer <br />
          Channels
        </p>
        <p class="card-text">
          For optimal <br />
          security of trade
        </p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="icon-card card-6">
        <img src="../../assets/images/SVG/solution-6.svg" alt="" />
        <p class="card-title">Crypto & Fiat Conversion</p>
        <p class="card-text">
          For trustless <br />
          financial interactions
        </p>
      </div>
    </div>
  </carousel>
</div>

<!-- french content -->

<div class="about-component" *ngIf="g.language === 'Fr'">
  <div class="solutions-section">
    <div class="heading mobile">
      <p class="title">Une solution One Stop</p>
      <p class="title-sub">
        Briser les silos. Rendre le commerce plus intelligent.
      </p>
    </div>
    <div class="content">
      <div class="text">
        <span class="title web"> Une solution One Stop </span>
        <span class="title-sub web">
          Briser les silos. Rendre le commerce plus intelligent.
        </span>
        <p class="desc">
          <strong>AntelopeFi</strong> est un écosystème complet, intégré et
          inter-chaînes, conçu pour résoudre les problèmes de fragmentation et
          de facilité d'utilisation. La plateforme connecte les parties
          prenantes, réduit les barrières et améliore l'accès en offrant une
          solution de bout en bout pour le secteur du commerce et de la
          logistique décentralisé.
        </p>
      </div>
      <carousel
        class="slider icon-cards web"
        [width]="830"
        [arrowsOutside]="true"
        [height]="230"
        [arrowsTheme]="this.theme"
      >
        <div class="carousel-cell">
          <div class="icon-card card-1">
            <img src="../../assets/images/SVG/solution-1.svg" alt="" />
            <p class="card-title">Accès au Defi Pools de liquidité</p>
            <p class="card-text">
              Pour un financement du commerce à travers la chaîne et à coût
              optimisé
            </p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="icon-card card-2">
            <img src="../../assets/images/SVG/solution-2.svg" alt="" />
            <p class="card-title">Tokeniser les factures en NFT's</p>
            <p class="card-text">
              Pour une vérification, un transfert et un stockage sans heurts
            </p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="icon-card card-3">
            <img src="../../assets/images/SVG/solution-3.svg" alt="" />
            <p class="card-title">Tirer parti des oracles décentralisés</p>
            <p class="card-text">
              Pour une intégration hors chaîne avec les processus du monde réel
            </p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="icon-card card-4">
            <img src="../../assets/images/SVG/solution-4.svg" alt="" />
            <p class="card-title">
              Évaluer le crédit et la couverture d'assurance
            </p>
            <p class="card-text">Pour une minimisation efficace des risques</p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="icon-card card-5">
            <img src="../../assets/images/SVG/solution-5.svg" alt="" />
            <p class="card-title">Canaux de pair à pair</p>
            <p class="card-text">
              Pour un résultat optimal <br />
              la sécurité du commerce
            </p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="icon-card card-6">
            <img src="../../assets/images/SVG/solution-6.svg" alt="" />
            <p class="card-title">Conversion Crypto & Fiat</p>
            <p class="card-text">Pour des interactions financières en toute confiance</p>
          </div>
        </div>
      </carousel>
      <div class="image mobile">
        <img
          *ngIf="this.globals.theme == 'dark'"
          src="../../assets/images/Sailing Schedule Screen.svg"
          alt=""
        />
        <img
          *ngIf="this.globals.theme == 'light'"
          src="../../assets/images/Sailing Schedule Screen-Light.svg"
          alt=""
        />
      </div>
    </div>
    <div class="image web">
      <img
        *ngIf="this.globals.theme == 'dark'"
        src="../../assets/images/Sailing Schedule Screen.svg"
        alt=""
      />
      <img
        *ngIf="this.globals.theme == 'light'"
        src="../../assets/images/Sailing Schedule Screen-Light.svg"
        alt=""
      />
    </div>
  </div>
  <carousel
    class="slider icon-cards mobile"
    [height]="185"
    [arrows]="false"
    [cellsToShow]="2"
  >
    <div class="carousel-cell">
      <div class="icon-card card-1">
        <img src="../../assets/images/SVG/solution-1.svg" alt="" />
        <p class="card-title">Access to Defi Liquidity Pools</p>
        <p class="card-text">
          For cross-chain and cost-optimized trade financing
        </p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="icon-card card-2">
        <img src="../../assets/images/SVG/solution-2.svg" alt="" />
        <p class="card-title">Tokenize Invoices into NFT’s</p>
        <p class="card-text">
          For frictionless verification, transfer, and storage
        </p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="icon-card card-3">
        <img src="../../assets/images/SVG/solution-3.svg" alt="" />
        <p class="card-title">Leverage Decentralized Oracles</p>
        <p class="card-text">
          For off-chain integration with real world processes
        </p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="icon-card card-4">
        <img src="../../assets/images/SVG/solution-4.svg" alt="" />
        <p class="card-title">Assess credit and Insurance Coverage</p>
        <p class="card-text">For effective risk-minimization</p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="icon-card card-5">
        <img src="../../assets/images/SVG/solution-5.svg" alt="" />
        <p class="card-title">
          Peer-to-Peer <br />
          Channels
        </p>
        <p class="card-text">
          For optimal <br />
          security of trade
        </p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="icon-card card-6">
        <img src="../../assets/images/SVG/solution-6.svg" alt="" />
        <p class="card-title">Crypto & Fiat Conversion</p>
        <p class="card-text">
          For trustless <br />
          financial interactions
        </p>
      </div>
    </div>
  </carousel>
</div>
