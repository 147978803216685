<div class="governance-container" *ngIf="g.language === 'En'">
  <div class="title">
    <h1>Antelope Governance</h1>
  </div>
  <div class="title-sub">
    <h1>Decentralizing Governance. Resisting Censorship.</h1>
  </div>
  <div class="desc">
    <p>
      Devoted to the principles of decentralization, the Antelope ecosystem
      adopts a hierarchical, two-tier, and skill-based governance infrastructure
      comprising a Technical Council and a Business Council
    </p>
    <p>
      The mechanism explores Conviction or Quadratic Voting systems to
      effectively mitigate sybil attacks, collusion, and other risk vectors that
      are typical of time-boxed governance procedures.
    </p>
  </div>

  <div class="body">
    <div class="col col-1">
      <div class="title">
        <h1>Technical Council</h1>
      </div>
      <div class="desc">
        <p>
          The TC votes on matters concerning protocol upgrades, inflation
          adjustments, and commission parameters. Initially appointed by the
          AntelopeFi, subsequent applications for council membership will be
          subject to a two-third consensus of the ANTLP community
        </p>
      </div>
    </div>
    <div class="col col-2">
      <div class="title">
        <h1>Business Council</h1>
      </div>
      <div class="desc">
        <p>
          The BC is the representative body of the general ANTLP community,
          comprising individual users, token holders, and third-party service
          providers from the ecosystem. The council’s primary function is to
          facilitate the election of the TC
        </p>
      </div>
    </div>
  </div>
  <div class="flow-image">
    <img
      *ngIf="this.globals.theme == 'light'"
      src="../../assets/images/SVG/flow-diagram.svg"
      alt=""
    />
    <img
      *ngIf="this.globals.theme == 'dark'"
      src="../../assets/images/SVG/flow-diagram-dark.svg"
      alt=""
    />
  </div>
</div>
<div class="governance-container" *ngIf="this.g.language === 'Fr'">
  <div class="title">
    <h1>Gouvernance d'Antilope</h1>
  </div>
  <div class="title-sub">
    <h1>Décentraliser la gouvernance. Résister à la censure</h1>
  </div>
  <div class="desc">
    <p>
      Dévoué aux principes de la décentralisation, l'écosystème d'Antilope
      adopte une infrastructure de gouvernance hiérarchique, à deux niveaux, et
      basée sur les compétences, comprenant un Conseil technique et un Conseil
      d'entreprise
    </p>
    <p>
      Le mécanisme explore les systèmes de vote par conviction ou quadratique
      pour d'atténuer efficacement les attaques par sybiles, la collusion et les
      autres vecteurs de risque qui sont typiques des procédures de gouvernance
      encadrées dans le temps
    </p>
  </div>

  <div class="body">
    <div class="col col-1">
      <div class="title">
        <h1>Conseil technique</h1>
      </div>
      <div class="desc">
        <p>
          Le CT vote sur les questions concernant les mises à jour du protocole,
          les ajustements de l'inflation et les paramètres de la commission.
          Initialement nommé par le AntelopeFi, les demandes ultérieures
          d'adhésion au conseil seront soumises à un consensus des deux tiers de
          la communauté ANTLP
        </p>
      </div>
    </div>
    <div class="col col-2">
      <div class="title">
        <h1>Conseil des affaires</h1>
      </div>
      <div class="desc">
        <p>
          La CB est l'organe représentatif de la communauté générale d'ANTLP,
          composée d'utilisateurs individuels, de détenteurs de jetons et de
          fournisseurs de services tiers fournisseurs de services tiers de
          l'écosystème. La fonction principale du conseil est de faciliter
          l'élection du CT
        </p>
      </div>
    </div>
  </div>
  <div class="flow-image">
    <img
      *ngIf="this.globals.theme == 'light'"
      src="../../assets/images/SVG/flow-diagram.svg"
      alt=""
    />
    <img
      *ngIf="this.globals.theme == 'dark'"
      src="../../assets/images/SVG/flow-diagram-dark.svg"
      alt=""
    />
  </div>
</div>
