import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Globals } from '../globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  g: Globals;

  public isErrorNotification = false;
  public isNotify = false;
  public apiResponse = '';
  public waitlistForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, this.emailValidator]),
  });

  constructor(public globals: Globals, private http: HttpClient) {
    this.g = globals;
  }

  ngOnInit(): void {}

  emailValidator(control: { value: string }) {
    if (control.value) {
      const matches = control.value.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
      return matches ? null : { invalidEmail: true };
    } else {
      return null;
    }
  }

  showNotification(message: string, isError: boolean) {
    this.apiResponse = message;
    this.isErrorNotification = isError;
    this.isNotify = true;
  }

  onButtonClick() {
    const emailControl = this.waitlistForm.controls.email;
    if (emailControl.errors) {
      if (emailControl.errors.required)
        this.showNotification('Please Enter your email.', true);
      else if (emailControl.errors.invalidEmail)
        this.showNotification('Please Enter a valid email.', true);
    } else {
      this.isNotify = false;

      let waitlistEmail = this.waitlistForm.value;
      const url = 'http://18.224.18.114:3000/v1/users/waitList';
      this.http
        .post(url, waitlistEmail, {
          headers: new HttpHeaders({
            'Content-Type': 'application/JSON',
          }),
        })
        .subscribe((response) => {
          this.showNotification(
            'You have been successfully subscribed.',
            false
          );
          setTimeout(() => this.showNotification('', false), 4000);
        }, (err) => {
          this.showNotification(err.error.message, true);
        });
    }
  }
}
