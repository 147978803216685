<!-- <div class="wave">
    <img src="../../assets/images/wave.gif" alt="">
</div> -->

<div class="footer-component-wrapper" *ngIf="g.language === 'En'">
  <div class="divider"></div>
  <div class="body">
    <div class="site-logo">
      <img src="../../assets/images/SVG/footer-logo.png" alt="" />
      <p class="web">
        An Inclusive, Decentralised Ecosystem for End-to-End Supply Chain
        Management & Trade Finance
      </p>
    </div>
    <div class="site-map">
      <ul>
        <li>
          <a routerLink="/about">About Us</a>
        </li>
        <li>
          <a routerLink="">Foundation</a>
        </li>
      </ul>
    </div>
    <div class="site-map">
      <ul>
        <li>
          <a routerLink="">Litepaper</a>
        </li>
        <li>
          <a routerLink="/token">Token</a>
        </li>
      </ul>
    </div>
    <div class="community">
      <div class="title">
        <h6>
          <a href="">Join Waitlist</a>
        </h6>
      </div>
      <div class="title">
        <h6>
          <a href="">Join our Community</a>
        </h6>
      </div>
      <div class="icons">
        <a href="https://discord.gg/48D4P2jE6t">
          <img src="assets/images/SVG/discord.svg" id="discord" />
        </a>
        <a href="https://t.me/AntelopeFi">
          <img src="assets/images/SVG/telegram 3d 4.png" id="telegram" />
        </a>
        <a href="https://twitter.com/AntelopeFi">
          <img src="assets/images/SVG/twitter.svg" id="twitter" />
        </a>
        <a href="https://www.linkedin.com/company/antelopefi-it/">
          <img src="assets/images/SVG/linkedin.webp" id="linkedin" />
        </a>
      </div>
    
        <div class="title margin-top-2 margin_bottom">
          <h6>
            Affiliated Organizations
          </h6>
        </div>
        <a href="https://nasscom.in/" target="_blank">
          <img src="../../assets/images/nasscom-logo_2_0.jpg" />
        </a>
      
        <a href="https://lafrenchtech.com/en/" target="_blank">
          <img style="width: 89px;margin-left:10px;" src="../../assets/images/frenchtechlogo.jpg" />
        </a>
      
    </div>
  </div>
</div>
<div class="footer-component-wrapper" *ngIf="g.language === 'Fr'">
  <div class="divider"></div>
  <div class="body">
    <div class="site-logo">
      <img src="../../assets/images/SVG/footer-logo.png" alt="" />
      <p class="web">
        Un écosystème inclusif et décentralisé pour la gestion de la chaîne
        d'approvisionnement de bout en bout et la gestion de la chaîne
        d'approvisionnement. gestion de la chaîne d'approvisionnement de bout en
        bout et le financement du commerce
      </p>
    </div>
    <div class="site-map">
      <ul>
        <li>
          <a routerLink="/about">A propos de nous</a>
        </li>
        <li>
          <a routerLink="">Fondation</a>
        </li>
      </ul>
    </div>
    <div class="site-map">
      <ul>
        <li>
          <a routerLink="">Litepaper</a>
        </li>
        <li>
          <a routerLink="/token">Token</a>
        </li>
      </ul>
    </div>
    <div class="community">
      <div class="title">
        <h6>
          <a href="">S'inscrire sur la liste d'attente</a>
        </h6>
      </div>
      <div class="title">
        <h6>
          <a href="">Rejoignez notre communauté</a>
        </h6>
      </div>
      <div class="icons">
        <a href="https://discord.gg/48D4P2jE6t">
          <img src="assets/images/SVG/discord.svg" id="discord" />
        </a>
        <a href="https://t.me/AntelopeFi">
          <img src="assets/images/SVG/telegram 3d 4.png" id="telegram" />
        </a>
        <a href="https://twitter.com/AntelopeFi">
          <img src="assets/images/SVG/twitter.svg" id="twitter" />
        </a>
        <a href="https://www.linkedin.com/company/antelopefi-it/">
          <img src="assets/images/SVG/linkedin.webp" id="linkedin" />
        </a>
      </div>
    </div>
  </div>
</div>
