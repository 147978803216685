<div class="community-component-container" id="community" *ngIf="g.language === 'En'">
    <div class="news-letter-container">
        <div class="bell">
            <img src="../../assets/images/SVG/bell-newsletter.png" alt="">
        </div>
        <div class="body">
            <div class="content">
                <span class="title">Subscribe to our Newsletter</span>
                <div class="form">
                    <input type="text" class="input-box" placeholder="Enter your E-mail">
                    <button>Subscribe</button>
                </div>
            </div>
            <div class="mail">
                <img src="../../assets/images/SVG/mail-newsletter.png" alt="">
            </div>
        </div>
    </div>
</div>

<div class="community-component-container" id="community" *ngIf="g.language === 'Fr'">
    <div class="news-letter-container">
        <div class="bell">
            <img src="../../assets/images/SVG/bell-newsletter.png" alt="">
        </div>
        <div class="body">
            <div class="content">
                <span class="title">Abonnez-vous à notre newsletter</span>
                <div class="form">
                    <input type="text" class="input-box" placeholder="Enter your E-mail">
                    <button>S’abonner</button>
                </div>
            </div>
            <div class="mail">
                <img src="../../assets/images/SVG/mail-newsletter.png" alt="">
            </div>
        </div>
    </div>
</div>