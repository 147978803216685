import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.css']
})
export class SocialLinksComponent implements OnInit {

  @Input() spacing: string | undefined;

  constructor() { }

  ngOnInit(): void {
    console.log(this.spacing)
  }

}
