import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
@Component({
  selector: 'app-token-asset',
  templateUrl: './token-asset.component.html',
  styleUrls: ['./token-asset.component.css']
})
export class TokenAssetComponent implements OnInit {

  g : Globals;
  constructor(public globals: Globals){
    this.g = globals;
  }
  
  ngOnInit(): void {
  }

}
