import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit {

  g : Globals;
  constructor(public globals: Globals){
    this.g = globals;
  }
  
  ngOnInit(): void {
  }

}
