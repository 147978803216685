import { Component , OnInit } from '@angular/core';
import { Globals } from './globals';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers : [Globals]
})
export class AppComponent implements OnInit{
  g: Globals;
  constructor(public globals: Globals){
      this.g = globals;
  }
  state : any  = "hide";
  
  public ngOnInit(): void {
    window.onbeforeunload = ()=>{ this.globals.scrollToTop() };
  }
  public ngAfterViewInit() : void {
    if(this.globals.theme === 'dark'){
      document.body.setAttribute('class','dark');
    }else{
      document.body.setAttribute('class','');
    }  
  }
  
}

