<div class="home-container">
  <!-- <img src="../../assets/images/earth_comp.gif" id="earth" alt=""> -->
  <div class="center" *ngIf="g.language === 'En'">
    <h1 class="head-1 header-item">A Decentralized Solution for</h1>
    <h1 class="head-2 header-item">
      End-to-End Supply Chain Management & Trade Finance
    </h1>
    <span class="description header-item">
      Streamline the purchase-to-pay process with integrated trade financing,
      real-time shipment tracking, secure document sharing,and seamless
      buyer-seller interactions. Generate stable DeFi returns with real-world
      assets, tokenized risks, and crypto-based derivatives.
    </span>
    <form action="" [formGroup]="waitlistForm">
      <div class="form-item waitlist">
        <input
          type="email"
          name="email"
          placeholder="Enter your E-mail"
          autocomplete="off"
          formControlName="email"
        />

        <button (click)="onButtonClick()" type="button" class="">
          Join Waitlist
        </button>
      </div>

      <p
        [ngClass]="{
          emailMessageOrange: this.isErrorNotification,
          emailMessageGreen: !this.isErrorNotification
        }"
        *ngIf="this.isNotify"
      >
        {{ apiResponse }}
      </p>
    </form>
  </div>
  <div class="center" *ngIf="this.g.language === 'Fr'">
    <h1 class="head-1 header-item">Une solution décentralisée pour</h1>
    <h1 class="head-2 header-item">
      tout le processus Supply Chain ainsi que le Trade Finance.
    </h1>
    <span class="description header-item">
      Simplifier le processus de l’achat au paiement avec de la trade finance
      intégrée, suivi en temps réel des cargaisons,partage sécurisé des
      documents, ainsi qu’une transparence entre l’acheteur et le vendeur.
      Générer des revenus Defi stables avec des actifs réels, des risques
      tokenizé s, produits dérivés sur crypto.
    </span>
    <div class="waitlist">
      <input
        type="email"
        id="email"
        placeholder="saisissez votre e-mail"
        autocomplete="off"
      />
      <button class="">Inscrivez-vous sur notre liste d'attente</button>
    </div>
  </div>
</div>
