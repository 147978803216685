<div class="container">

    <div class="jumbotron">
      <div class="container">
        <h1 class="display-3">ng2-animate-on-scroll example</h1>
        <p class="lead">Scroll down for a few examples</p>
      </div>
    </div>
  
    <div class="row p-5">
  
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card  " animateOnScroll animationName="animated fadeInDown">
          <div class="card-block">
            <div class="card-title">
              <h3>One</h3>
            </div>
            <div class="card-text">
              <p>Cards that ...</p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card   animated-delay-1" animateOnScroll animationName="animated fadeInDown">
          <div class="card-block">
            <div class="card-title">
              <h3>Two</h3>
            </div>
            <div class="card-text">
              <p>... fade in at ...</p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card   animated-delay-2" animateOnScroll animationName="animated fadeInDown">
          <div class="card-block">
            <div class="card-title">
              <h3>Three</h3>
            </div>
            <div class="card-text">
              <p>... different times.</p>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  
    <div class="row p-5">
      <div class="col  " animateOnScroll animationName="animated fadeInLeft">
        <h4>Divs containing other elements like buttons or forms</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet elit feugiat, venenatis turpis id, pharetra felis. Cras mollis vitae orci eget volutpat. Proin dapibus massa vitae lacus maximus, vitae cursus diam sodales. Curabitur laoreet nunc sit amet est sagittis rutrum. Nulla at ante sed elit scelerisque vestibulum. Vivamus commodo mauris ante, quis varius ante dictum vel. Mauris in est volutpat, auctor diam ut, pretium est. Suspendisse ...</p>
  
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-secondary">Left</button>
          <button type="button" class="btn btn-secondary">Middle</button>
          <button type="button" class="btn btn-secondary">Right</button>
        </div>
      </div>
    </div>
  
    <div class="row p-5">
      <div class="col  " animateOnScroll animationName="animated fadeInRight">
        <h4>Sections of text</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet elit feugiat, venenatis turpis id, pharetra felis. Cras mollis vitae orci eget volutpat. Proin dapibus massa vitae lacus maximus, vitae cursus diam sodales. Curabitur laoreet nunc sit amet est sagittis rutrum. Nulla at ante sed elit scelerisque vestibulum. Vivamus commodo mauris ante, quis varius ante dictum vel. Mauris in est volutpat, auctor diam ut, pretium est. Suspendisse ...</p>
      </div>
    </div>
  
    <div class="row p-5">
      <div class="col">
        <h4>Images</h4>
  
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6 p-3">
  
        <img src="../assets/sunset.jpg" 
             class="img-fluid rounded float-left  "
             animateOnScroll animationName="animated zoomInDown" />
  
      </div>
      <div class="col-md-12 col-lg-6 p-3">
  
        <img src="../assets/tiger.jpg" 
             class="img-fluid rounded float-left  "
             animateOnScroll animationName="animated zoomInDown" />
  
      </div>
      <div class="col-md-12 col-lg-6 p-3">
  
        <img src="../assets/mountains.jpg" 
             class="img-fluid rounded float-left  "
             animateOnScroll animationName="animated zoomInDown" />
  
      </div>
    </div>
  
  </div>