<div class="about-container" *ngIf="g.language === 'En'">
    <div class="title">
      <span> AntelopeFi </span>
    </div>
    <div class="title-sub">
      <h1>A Decentralized Foundation</h1>
    </div>
    <div class="desc">
      <p>
        SMEs contribute substantially to the global economic growth, but legacy
        systems pave thorny roads for such entities through unequitable barriers
        and regulatory norms. Similarly, the traditional Trade & Logistics domain
        is not conducive to alternative investors, especially those indulging in
        crypto.
      </p>
      <p class="web">
        AntelopeFi embarks on a mission to disrupt this scenario, striving to
        establish a decentralized, trustless, and automated ecosystem for traders
        and financiers across magnitudes. To this end, the foundation has
        developed the Antelope protocol, benefitting a geographically distributed
        users’ community and envisioning a future with harmonious connectivity,
        collaboration, and seamless integration between stakeholders of the global
        Trade & Logistics sector.
      </p>
    </div>
  </div>
  <!-- French -->
  <div class="about-container" *ngIf="g.language === 'Fr'">
    <div class="title">
      <span> AntelopeFi </span>
    </div>
    <div class="title-sub">
      <h1>Une fondation décentralisée</h1>
    </div>
    <div class="desc">
      <p>
        Les Pme contribuent de manière substantiellement à la croissance
        économique mondiale, mais les systèmes existants leur ouvrent des voies
        épineuses en raison d'obstacles et de normes réglementaires inéquitables.
        De la même manière , le domaine traditionnel du commerce et de la
        logistique n'est pas propice aux investisseurs alternatifs, en particulier
        ceux qui s'adonnent aux crypto-monnaies.
      </p>
      <br>
      <p>
        AntelopeFi se lance dans une mission pour perturber ce scénario, s'efforçant de
        établir un écosystème décentralisé, sans confiance et automatisé pour les commerçants
        et financiers de toutes tailles. A cette fin, la fondation a développé
        le protocole Antelope, au bénéfice d’une clientèle géographiquement dispersée
        communauté et envisageant un avenir avec une connectivité harmonieuse, la collaboration,
        et une intégration transparente entre les parties prenantes du secteur mondial du commerce et de la logistique.
      </p> 
    </div>
  </div>
  