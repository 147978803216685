<div class="token-asset-container" *ngIf="g.language === 'En'">
  <div class="title">
    <h1>A Productive Crypto Asset</h1>
  </div>
  <div class="title-sub">
    <h1>Empowering Individuals. Maximizing Returns.</h1>
  </div>
  <div class="icon-cards">
    <div class="row">
      <div class="icon-card">
        <div class="image-outer">
          <div class="image">
            <img src="../../assets/images/SVG/token-asset-1.svg" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="title">
            <h1>Governance</h1>
          </div>
          <div class="desc">
            <p>
              ANTLP owners gain voting rights to participate in the protocol’s
              decentralized and censorship resistant decision-making procedure
              by electing council members.
            </p>
          </div>
        </div>
      </div>
      <div class="icon-card">
        <div class="image-outer">
          <div class="image">
            <img src="../../assets/images/SVG/token-asset-2.svg" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="title">
            <h1>Staking Rewards</h1>
          </div>
          <div class="desc">
            <p>
              The protocol distributes 80% of fees revenues to individuals who
              participate in the reward sharing mechanism by staking ANTLP
              tokens.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="icon-card">
        <div class="image-outer">
          <div class="image">
            <img src="../../assets/images/SVG/token-asset-3.svg" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="title">
            <h1>Liquidity Mining</h1>
          </div>
          <div class="desc">
            <p>
              Lenders and liquidity miners receive high returns in ANTLP tokens
              as an incentive for their contributions to the Antelope ecosystem.
            </p>
          </div>
        </div>
      </div>
      <div class="icon-card">
        <div class="image-outer">
          <div class="image">
            <img src="../../assets/images/SVG/token-asset-4.svg" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="title">
            <h1>Loan Repayment Rewards</h1>
          </div>
          <div class="desc">
            <p>
              Trade originators receive ANTLP tokens from the reward pool upon
              complying fully with the terms of their trade finance agreements.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="token-asset-container" *ngIf="this.g.language === 'Fr'">
  <div class="title">
    <h1>Un actif cryptographique productif</h1>
  </div>
  <div class="title-sub">
    <h1>Responsabiliser les individus. Maximiser les rendements.</h1>
  </div>
  <div class="icon-cards">
    <div class="row">
      <div class="icon-card">
        <div class="image-outer">
          <div class="image">
            <img src="../../assets/images/SVG/token-asset-1.svg" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="title">
            <h1>Gouvernance</h1>
          </div>
          <div class="desc">
            <p>
              Les propriétaires d'ANTLP obtiennent des droits de vote pour
              participer à la prise de décision décentralisée du protocole et
              résistant à la censure en élisant les membres du conseil.
            </p>
          </div>
        </div>
      </div>
      <div class="icon-card">
        <div class="image-outer">
          <div class="image">
            <img src="../../assets/images/SVG/token-asset-2.svg" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="title">
            <h1>Récompenses pour le jalonnement</h1>
          </div>
          <div class="desc">
            <p>
              Le protocole distribue 80 % des revenus des commissions aux
              personnes qui participent au mécanisme de partage des récompenses
              en misant des jetons ANTLP en échange de jetons.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="icon-card">
        <div class="image-outer">
          <div class="image">
            <img src="../../assets/images/SVG/token-asset-3.svg" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="title">
            <h1>Liquidity Mining</h1>
          </div>
          <div class="desc">
            <p>
              Les prêteurs et les mineurs de liquidité reçoivent des rendements
              élevés en jetons ANTLP pour les récompenser de leurs contributions
              à l'écosystème Antelope.
            </p>
          </div>
        </div>
      </div>
      <div class="icon-card">
        <div class="image-outer">
          <div class="image">
            <img src="../../assets/images/SVG/token-asset-4.svg" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="title">
            <h1>Récompenses pour le remboursement des prêts</h1>
          </div>
          <div class="desc">
            <p>
              Les initiateurs de commerce reçoivent des jetons ANTLP de la
              réserve de récompenses lorsqu'ils respectent pleinement les
              conditions de leurs accords de financement du commerce. respectent
              pleinement les conditions de leurs accords de financement du
              commerce.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
