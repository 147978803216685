<div class="topnav" id="myTopnav">
    <div class="menu-items" [class.hide]="state === 'hide'" *ngIf="g.language === 'En'">
      
        <a routerLink="/" routerLinkActive="active">Home</a>
        <a routerLink="/about" routerLinkActive="active">About Us</a>
        <a routerLink="/token" routerLinkActive="active">Token</a>
        <a class="launch-button"   href="http://app.antelopefi.xyz/" target="_blank">Launch App </a>
    </div>
    <div class="menu-items" [class.hide]="state === 'hide'" *ngIf="g.language === 'Fr'">
        <a routerLink="/" routerLinkActive="active">Accueil</a>
        <a routerLink="/about" routerLinkActive="active">À propos de nous</a>
        <a routerLink="/token" routerLinkActive="active">Token</a>
        <a class="launch-button"   href="http://app.antelopefi.xyz/" target="_blank">Démarrer l'application </a>
    </div>
    <div class="menu-icons">

      
          
      
        <a href="javascript:void(0);" class="language" (click)='langChange()' >
            <img *ngIf="this.globals.theme === 'dark'" src="assets/images/SVG/language-icon-dark.png">
            <img *ngIf="this.globals.theme === 'light'" src="assets/images/SVG/language-icon.png"> 
            <span> &nbsp; {{g.language}}</span>
        </a>
        <div class="dark-mode-toggle"  (click)='themeChange()'>
            <img [class.transform]="g.theme == 'dark'" src="{{themeImage}}" alt="">    
        </div>
        <a href="javascript:void(0);" class="burger" (click)='toggle()' >
            <img *ngIf="this.globals.theme === 'light'" src="assets/images/SVG/burger-icon.svg">
            <img *ngIf="this.globals.theme === 'dark'" src="assets/images/SVG/burger-icon-dark.svg">
        </a>
       
      
       
    </div>
    
    
</div>