import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  g : Globals;
  constructor(public globals: Globals){
    this.g = globals;
  }
  scrollValue : any = 0;
  lastScrollTop = 0;
  length : number = 0;
  elementArray : Array<any> = [];
  ngOnInit(): void {
    // this.scrollInit();
    this.globals.scrollToTop();
  }
  scrollInit(){
    const sections = document.getElementsByClassName('scroll-section');
    this.length = sections.length -1;
    document.oncontextmenu = ()=> false;
    this.elementArray = Array.from(sections);
    // window.addEventListener('scroll',
    //     this.globals.throttle(this.scrollFunction,500)    
    // );
    
  }
  scrollFunction = ()=>{
    var direction = this.detectScroll(); 
    console.log(direction +","+ this.scrollValue + ","+ this.length);
    if(direction && this.scrollValue < this.length){
          this.scrollValue++;
          console.log(this.elementArray[this.scrollValue].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"}));
    }else if( !direction && this.scrollValue > 0){
          this.scrollValue--;
          this.elementArray[this.scrollValue].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }
  }
  detectScroll(){ // or window.addEventListener("scroll"....
    var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    var flag;
    if (st > this.lastScrollTop){
      flag = true;
    } else {
      flag = false;
    }
    this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    return flag;
  }
  
  
}
