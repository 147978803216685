<div class="about-container" *ngIf="g.language === 'En'">
    <div class="section-2 section">
        <span class="title mobile">
            Encrypted & Secure
        </span>
        <span class="title-sub mobile">
            Prioritizing Privacy. Respecting Autonomy.
        </span>
        <div class="image mobile">
            <img src="../../assets/images/SVG/encrypted&secure.png" alt="">
        </div>
        <div class="description">
            <span class="title web">
                Encrypted & Secure
            </span>
            <span class="title-sub web">
                Prioritizing Privacy. Respecting Autonomy.
            </span>
            <p class="para-1">
                Leveraging advanced homomorphic cryptography 
                and zero-knowledge proofs, Antelope prioritizes 
                the individual’s right to privacy, autonomy, and 
                choice while securing network communication and 
                retaining optimal verifiability for legitimate 
                regulatory compliance. 
            </p>
            <p class="para-2">
                Encrypted and peer-to-peer channels protect 
                sensitive business information in transit and 
                distributed storage minimizes risks concerning 
                breach and loss of data.             
            </p>
        </div>
        <div class="image web">
            <img src="../../assets/images/SVG/encrypted&secure.png" alt="">
        </div>
    </div>
</div>

<!-- Fren -->

<div class="about-container" *ngIf="g.language === 'Fr'">
    <div class="section-2 section">
        <span class="title mobile">
            Crypté et sécurisé
        </span>
        <span class="title-sub mobile">
            
Donner la priorité à la confidentialité. Respecter l’autonomie.

        </span>
        <div class="image mobile">
            <img src="../../assets/images/SVG/encrypted&secure.png" alt="">
        </div>
        <div class="description">
            <span class="title web">
                Crypté et sécurisé
            </span>
            <span class="title-sub web">
                
Donner la priorité à la confidentialité. Respecter l’autonomie.

            </span>
            <p class="para-1">
                Antelope donne la priorité au droit de l'individu à la vie privée, à l'autonomie et au choix tout en sécurisant les communications du réseau et en conservant une vérifiabilité optimale pour une conformité réglementaire légitime.

            </p>
            <p class="para-2">
                Des canaux cryptés et peer to peer protègent les informations  sensibles en transit et le stockage distribué minimise les risques de violation et de perte de données.     
            </p>
        </div>
        <div class="image web">
            <img src="../../assets/images/SVG/encrypted&secure.png" alt="">
        </div>
    </div>
</div>