<div class="images-container legacy-problems">

    <div class="title">
        <span>
            The Legacy Problems
        </span>
    </div>
    <div class="section-content">
        <div class="tabs-container">
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label class="tab-title" [color]="color" [backgroundColor] = "bgColor">
                        <div class="tab-title">
                            <span>
                                Access
                            </span>
                        </div>
                    </ng-template>
                    <div class="tab-content tab-1">
                        <div class="desc">
                            <p>
                                Traditional financial institutions raise entry 
                                barriers for SMEs and limit their access to trade 
                                capital with complicated onboarding, exclusive 
                                regulations, and exorbitant costs.
                                DeFi alternatives are challenging for
                                traders and investors due to information constraints,
                                steep learning curves, and the lack of user-friendly integrated solutions. 
                            </p>
                        </div>
                        <div class="image">
                            <img src="assets/images/SVG/access.png" alt="">
                        </div>
                    </div>
                </mat-tab>
                
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="tab-title">
                            <span>
                                Operations
                            </span>
                        </div>
                    </ng-template>
                    <div class="tab-content tab-2">
                        <div class="desc">
                            <p>
                                Enterprises face severe accountability crises
                                while conducting domestic and international trades, 
                                due to inadequate solutions for shipment tracking 
                                and supply chain monitoring. The consequent lack 
                                of transparency results in performance bottlenecks, 
                                manipulation risks, inefficient resource allocation, 
                                quality compromises, revenue loss, and non-optimal ROIs. 
                            </p>
                        </div>
                        <div class="image">
                            <img src="assets/images/SVG/ship.svg" alt="">
                        </div>
                    </div>
                </mat-tab>
                
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="tab-title">
                            <span>
                                Integration
                            </span>
                        </div>
                    </ng-template>
                    <div class="tab-content tab-2">
                        <div class="desc">
                            <p>
                                The fragmented trade and supply chain ecosystem 
                                compels stakeholders to use different solutions 
                                for different needs and complicates business 
                                processes with diverse reference frameworks, 
                                silos, and low interoperability. 
                                The absence of frictionless integrations between 
                                conventional and crypto-based finance is an additional 
                                hindrance to cross-border trading.   
                            </p>
                        </div>
                        <div class="image">
                            <img src="assets/images/integration.png" alt="">
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    
</div>