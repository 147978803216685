import { Component, OnInit } from '@angular/core';
import {Globals} from '../globals';
@Component({
  selector: 'app-token-governance',
  templateUrl: './token-governance.component.html',
  styleUrls: ['./token-governance.component.css']
})
export class TokenGovernanceComponent implements OnInit {

  g: Globals;
  
  constructor(public globals: Globals){
      this.g = globals;
  }

  ngOnInit(): void {
  }

}
