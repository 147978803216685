import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  g : Globals ;

  waitlistEmail = new FormControl('');
  constructor(public globals: Globals){
    this.g = globals;
  }

  ngOnInit(): void {
  }
}
