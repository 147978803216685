import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  name: string = 'vamsi';
  language: string = 'En';
  theme: string = 'light';

  scrollToTop(){
    window.scrollTo(0,0);
  }

  
  
}

