import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.css']
})
export class AboutPageComponent implements OnInit {
  g : Globals;
  constructor(public globals: Globals){
    this.g = globals;
  }
  ngOnInit(): void {
    this.globals.scrollToTop();
  }

}
