<div class="nav-bar-container">
    <div class="header">
        <div class="logo">
            <img src="assets/images/SVG/logo.png">
        </div>
        <div class="navbar-container">
            <app-navbar></app-navbar>
        </div>
    </div>
</div>
