<div class="ecosystem" *ngIf="g.language === 'En'">
    <div class="content">
        <span class="mobile heading">
            The <br> Antelope Ecosystem
        </span>
        <div class="originators">
            <div class="content">
                <span class="title">
                    Trade Originators
                </span>
                <p>
                    Small, medium, and large enterprises can leverage
                    the Antelope  protocol to raise capital for trades, 
                    borrow collateral for loans, 
                    issue tokenized invoices, and access 
                    a diverse range of logistic services. 
                    The platform connects enterprises with 
                    suppliers, financiers, and service providers, 
                    boosting their potential for growth.
                </p>
            </div>
            <div class="image">
                <img src="../../assets/images/trade-originators.png" alt="">
            </div>
        </div>
        <div class="middle-section">
            <div class="section-heading web">
                <span class="section-title">
                    The Antelope <br>   
                    Ecosystem
                </span>
                <div class="image">
                    <img src="../../assets/images/ecosystem.png" alt="">
                </div>
            </div>
            <div class="liquidity-providors"> 
                <div class="content">
                    <h1 class="title">
                        Liquidity Providers
                    </h1>
                    <p>
                        Yield Farmers can participate in decentralized 
                        trade financing to generate stable and 
                        short-term returns by investing in assets 
                        with tangible value in the real world. 
                        Additionally, they gain access to derivatives 
                        and tokenized risk exposures, specially designed 
                        to suit the trade and logistics sector.
                    </p>
                </div>
                <div class="image">
                    <img src="../../assets/images/liuidity-providors.svg" alt="">
                </div>
            </div>
           
        </div>
        <div class="service-institutions">
            <div class="content">
                <span class="title">
                    Service Institutions
                </span>
                <p>
                    Institutional entities and crypto-based companies 
                    can offer financial and non-financial services on 
                    Antelope, such as insurance and investment opportunities, 
                    entitling them to promising revenue shares through 
                    transaction fees and rewards. Service Institutions 
                    are essential to the ecosystem, forwarding its 
                    vision and broadening its scope.  
                </p>
            </div>
            <div class="image">
                <img src="../../assets/images/SVG/service-inst.svg" alt="">
            </div>
        </div>
    </div>
</div>
<!-- French -->
<div class="ecosystem" *ngIf="g.language === 'Fr'">
    <div class="content">
        <span class="mobile heading">
            L'écosystème <br> Antelope
        </span>
        <div class="originators">
            <div class="content">
                <span class="title">
                    Initiateur de commerce
                </span>
                <p>
                    Les petites, moyennes et grandes entreprises peuvent s'appuyer sur le protocole Antilope pour lever des capitaux pour des transactions, emprunter des garanties pour des prêts, émettre des factures tokenisées et accéder à une gamme variée de services logistiques. La plateforme connecte les entreprises avec les fournisseurs, les financiers et les prestataires de services, stimulant ainsi leur potentiel de croissance.
                </p>
            </div>
            <div class="image">
                <img src="../../assets/images/trade-originators.png" alt="">
            </div>
        </div>
        <div class="middle-section">
            <div class="section-heading web">
                <span class="section-title">
                    L'écosystème Antelope
                </span>
                <div class="image">
                    <img src="../../assets/images/ecosystem.png" alt="">
                </div>
            </div>
            <div class="liquidity-providors"> 
                <div class="content">
                    <h1 class="title">
                        Fournisseurs de liquidité
                    </h1>
                    <p>
                        Les yield farmers peuvent participer au financement décentralisé du commerce pour générer des rendements stables et à court terme en investissant dans des actifs ayant une valeur tangible dans le monde réel.
                        De plus, ils ont accès à des produits dérivés et à des expositions au risque tokénizées, spécialement conçus pour le secteur du commerce et de la logistique.
                        
                    </p>
                </div>
                <div class="image">
                    <img src="../../assets/images/liuidity-providors.svg" alt="">
                </div>
            </div>
           
        </div>
        <div class="service-institutions">
            <div class="content">
                <span class="title">
                    
                    Établissements de services

                </span>
                <p>
                    Les entités institutionnelles et les sociétés basées sur les crypto-monnaies peuvent proposer des services financiers et non financiers sur Antelope, tels que des assurances et des opportunités d'investissement, ce qui leur donne droit à des parts de revenus prometteuses grâce aux frais de transaction et aux récompenses.
                    Les institutions de services sont essentielles à l'écosystème pour faire avancer sa vision et élargir son champ d'action.

                </p>
            </div>
            <div class="image">
                <img src="../../assets/images/SVG/service-inst.svg" alt="">
            </div>
        </div>
    </div>
</div>
