import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';

@Component({
  selector: 'app-token-page',
  templateUrl: './token-page.component.html',
  styleUrls: ['./token-page.component.css']
})
export class TokenPageComponent implements OnInit {
  g : Globals;
  constructor(public globals: Globals){
    this.g = globals;
  }
  ngOnInit(): void {
    this.globals.scrollToTop();
  }
}
