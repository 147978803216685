<div class="advisors-container" *ngIf="g.language === 'En'">
  <div class="title">
    <h1>Honourable Advisors</h1>
  </div>
  <div class="title-sub">
    <h1>
      <!-- Passionate Team. Innovative Ideas. -->
    </h1>
  </div>
  <div class="image-cards">
    <div class="image-row">
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/Won-Kim.jpg" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Won Kim</h1>
          </div>
          <div class="card-sub">
            <h1>Advisor</h1>
          </div>
          <div class="card-desc">
            <p>
              Director of Blockchain Master’s at ESCE, Paris. 
              Head of International Digital Marketing Specialization. 
              Founder of PiggyTag & Art Genies. 
              Member of METALITY (Web 3 Marketing). 
              Member of Board of Directors DNA Global Analytics.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/laurent.png" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Laurent Fedi</h1>
          </div>
          <div class="card-sub">
            <h1>Legal Advisor</h1>
          </div>
          <div class="card-desc">
            <p>
              Professor in Kedge business school International Maritime law ,
              holds a doctorate in maritime law from the Faculty of Law and
              Political Science .Port Governance and Competition Law & Ship
              broker.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="advisors-container" *ngIf="g.language === 'Fr'">
  <div class="title">
    <h1>Honorables conseillers</h1>
  </div>
  <div class="title-sub">
    <h1>
      <!-- Passionate Team. Innovative Ideas. -->
    </h1>
  </div>
  <div class="image-cards">
    <div class="image-row">
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/Won-Kim.jpg" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Won Kim</h1>
          </div>
          <div class="card-sub">
            <h1>Conseiller</h1>
          </div>
          <div class="card-desc">
            <p>
              Directeur du Master Blockchain à l'ESCE, Paris. 
              Responsable de la spécialisation internationale en marketing digital. 
              Fondateur de PiggyTag & Art Genies. 
              Membre de METALITY (Web 3 Marketing). 
              Membre du conseil d'administration de DNA Global Analytics.
            </p>
          </div>
        </div>
      </div>
      <div class="image-card">
        <div class="image-outer">
          <img src="../../assets/images/laurent.png" alt="" />
        </div>
        <div class="body">
          <div class="card-title">
            <h1>Laurent Fedi</h1>
          </div>
          <div class="card-sub">
            <h1>Conseiller juridique</h1>
          </div>
          <div class="card-desc">
            <p>
              Professeur à la Kedge business school Droit maritime international
              ,est titulaire d'un doctorat en droit maritime de la Faculté de
              droit et de sciences politiques. de la Faculté de Droit et de
              Sciences Politiques. courtier.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
