<div class="engineering-component-container">
  <div class="partners-container">
    <div class="title" *ngIf="g.language === 'En'">
      <h1>Our Partners</h1>
    </div>
    <div class="title" *ngIf="g.language === 'Fr'">
      <h1>Nos partenaires</h1>
    </div>

    <div
      class="images-container"
      animateOnScroll
      animationName="animated fadeInLeftBig"
    >
      <a href="https://dfinity.org/" target="_blank" class="card-container">
        <div class="image-frame">
          <!--   animated-delay-1" animateOnScroll animationName="animated fadeInLeft"> -->

          <img
            *ngIf="this.globals.theme == 'light'"
            src="../../assets/images/DFINITY-horizontal-logo.png"
            alt=""
          />
          <img
            *ngIf="this.globals.theme == 'dark'"
            src="../../assets/images/DFINITY-horizontal-logo_whitetext.png"
            alt=""
          />
        </div>
      </a>
      <a href="https://defactor.com/" target="_blank" class="card-container">
        <div class="image-frame">
          <!--   animated-delay-1" animateOnScroll animationName="animated fadeInLeft"> -->

          <img
            *ngIf="this.globals.theme == 'light'"
            src="../../assets/images/DefactorLogoForLight.png"
            alt=""
          />
          <img
            *ngIf="this.globals.theme == 'dark'"
            src="../../assets/images/DefactorLogoForDark.png"
            alt=""
          />
        </div>
      </a>
    </div>
    <div class="margin-top--one"></div>
    <div
      class="images-container"
      animateOnScroll
      animationName="animated fadeInLeftBig"
    >
      <a href="https://cargox.io" target="_blank" class="card-container">
        <div class="image-frame">
          <!--   animated-delay-3" animateOnScroll animationName="animated fadeInLeft"> -->
          <img src="../../assets/images/SVG/partners-1.png" />
        </div>
      </a>
      <a
        href="https://visesaenergy.com   "
        target="_blank"
        class="card-container"
      >
        <div class="image-frame">
          <!--   animated-delay-2" animateOnScroll animationName="animated fadeInLeft"> -->
          <img src="../../assets/images/SVG/partners-2.png" />
        </div>
      </a>
    </div>
    <div class="margin-top--one"></div>
    <div
      class="images-container"
      animateOnScroll
      animationName="animated fadeInLeftBig"
    >
      <a
        href="https://www.ryallenergy.com/"
        target="_blank"
        class="card-container"
      >
        <div class="image-frame">
          <!--   animated-delay-1" animateOnScroll animationName="animated fadeInLeft"> -->
          <img src="../../assets/images/SVG/partners-4.png" />
        </div>
      </a>
      <a
        href="http://www.starofseasmarine.global/"
        target="_blank"
        class="card-container"
      >
        <div class="image-frame">
          <!--   animated-delay-1" animateOnScroll animationName="animated fadeInLeft"> -->
          <img src="../../assets/images/SVG/partners-5.png" />
        </div>
      </a>
    </div>
  </div>
</div>
