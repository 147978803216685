import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-advisors',
  templateUrl: './advisors.component.html',
  styleUrls: ['./advisors.component.css']
})
export class AdvisorsComponent implements OnInit {

  g : Globals ;

  waitlistEmail = new FormControl('');
  constructor(public globals: Globals){
    this.g = globals;
  }

  ngOnInit(): void {
  }

}
