import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';

@Component({
  selector: 'app-token-economics',
  templateUrl: './token-economics.component.html',
  styleUrls: ['./token-economics.component.css'],
})
export class TokenEconomicsComponent implements OnInit {
  g: Globals;
  constructor(public globals: Globals) {
    this.g = globals;
  }

  stats: any = [
    {
      name: 'Liquidity & Staking',
      percentage: '59.5%',
      color: '#FE815F',
    },
    {
      name: 'Seed',
      percentage: '10%',
      color: '#87045F',
    },

    {
      name: 'Private',
      percentage: '8%',
      color: '#BA2E5D',
    },
    {
      name: 'Public',
      percentage: '0.5%',
      color: '#FEF2CD',
    },
    {
      name: 'Advisors',
      percentage: '5%',
      color: '#FEC100',
    },
    {
      name: 'Team',
      percentage: '10%',
      color: '#FABB51',
    },
    {
      name: 'Governance',
      percentage: '7%',
      color: '#663201',
    },
  ];

  ngOnInit() {}
}
